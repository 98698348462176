import S3 from 'aws-s3';
import Compressor from 'compressorjs';

const AWS_ACCESS_KEY_ID=process.env.REACT_APP_TC_AWS_ACCESS_KEY_ID
const AWS_SECRET_ACCESS_KEY=process.env.REACT_APP_TC_AWS_SECRET_ACCESS_KEY

const awsData = JSON.parse(JSON.stringify({ 
  bucketName: 'top-court',
  region: "ap-southeast-1", 
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
  secretKeyId: AWS_SECRET_ACCESS_KEY // same as secretAccessKey
}));


export async function handleUpload(selectedFile, 
  compress = 0.5 // set to 0 to no need to compress
) {
    let newFileName = `${new Date().getTime()}`;
    awsData.dirName = `topcourt-staging`;
    let listURL = []
    if (selectedFile) {
      if (compress) {
        let compressedFile = await new Promise((resolve, reject) => {
          new Compressor(selectedFile, {      
            quality: compress,
            success(result) {
              console.log(result)
              resolve(result);
            },
            error(error) {
              console.log(error)
              reject(error);
            },
          });
        })
        let newFile = new File([compressedFile], newFileName, {type: "image/png", lastModified: new Date()});
        const S3Client = new S3(awsData);
        let imageFile = await S3Client.uploadFile(newFile, newFileName);
        console.log(imageFile.location)
        listURL.push(imageFile.location)
      } else {
        let newFile = new File([selectedFile], newFileName, {type: "image/png", lastModified: new Date()});
        console.log(newFile)
        const S3Client = new S3(awsData);
        let imageFile = await S3Client.uploadFile(newFile, newFileName);
        listURL.push(imageFile.location)
      }
    }
    return listURL
}