import React, { useState, useEffect } from "react";

import { Avatar, Button, Card, CardContent, Grid, Tab, Tabs, Tooltip } from "@mui/material";
import { getOrderDetail, updateOrder } from "services/orderServices";
import { getParamsFromURL } from "helper/Params";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TabContext } from "@mui/lab";

import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import moment from "moment";
import OrderInfo from "./detail/info";
import PaymentInfo from "./detail/payment";
import { Confirm } from "helper/Alert";
import { toastMessage } from "components/Toast/Toast";

const orderStatusMap = (data) => {
    if (data.orderStatus) {
        let message = "Awaiting Payment"
        let { orderStatus, orderType } = data;
        if (orderStatus[0].date != null) { message = "Awaiting Payment" }
        if (orderStatus[1].date != null) { message = "Paid" }
        // if (orderStatus[1].date != null && orderType == 'sell') { message = "Counter Pick Up" }
        // if (orderStatus[1].date != null && orderType == 'rent') { message = "Rent" }
        if (orderStatus[1].date != null && orderType == 'rent' && data.lastStatus == "rent") { message = "Rent" }
        if (orderStatus[2].date != null) { message = "Completed" }
        if (orderStatus[3].date != null) { message = "Payment Failed" }
        return message
    } else return '-'
}

const orderPaymentStatusMap = (data) => {
    if (data.orderStatus) {
        let message = "Awaiting Payment"
        let { orderStatus, orderType } = data;
        if (orderStatus[0].date != null) { message = "Awaiting Payment" }
        if (orderStatus[1].date != null) { message = "Paid" }
        // if (orderStatus[1].date != null && orderType == 'sell') { message = "Counter Pick Up" }
        if (orderStatus[1].date != null && orderType == 'rent' && data.lastStatus == "rent") { message = "Rent" }
        if (orderStatus[2].date != null) { message = "Completed" }
        if (orderStatus[3].date != null) { message = "Payment Failed" }
        return message
    } else return '-'
}

function OrderDetail ({

}) {

    const [ form, setForm ] = useState({
        _id: null,
        description: "",
        eventName: "",
        oldEventName: "",
        classLink: ""
    })

    const [ tabs, setTabs ] = useState(0)

    // load player info
    useEffect(() => {
        let { isEdit, params } = getParamsFromURL(window);
        if (params) { loadPlayerInfo(params) }
    }, [])

    useEffect(() => {
        console.log('here check form', form)
    }, [form])

    const loadPlayerInfo = async (id) => {
        let { data, error } = await getOrderDetail(id)
        if (error) { alert(error.message) }
        if (data) { 
            setForm({
                ...data,
                oldEventName: data.eventName
            }) 
        }
    }

    const onChange = (e) => {
        setForm(prev => ({
            ...prev, 
            ...e
        }))
    }

    const setUserStatus = (data) => {
        return data.superuser ? 'Superuser' : data.coachStatus ? 'Coach' : data.sessions > 0 ? 'Subscriber' : 'Regular' 
    }

    const updateOrderInfo = async () => {
        let body = {
            _id: form._id,
            description: form.description,
            eventName: form.eventName,
            classLink: form.classLink
        }
        console.log('ini cek body',body)
        let { data, error } = await updateOrder(body)
        if (data) { 
            toastMessage('Order has been updated', {
                toastId: "update-order-success",
                type: "success",
            });
        }
        if (error) { toastMessage(error ? error.message ? error.message : error : 'There is problem', {
            toastId: "update-order-success",
            type: "error",
        }); }
    }

    return (
        <Grid container xs={12} md={12}>
            <Grid item xs={12} md={12} style={{paddingRight: 2, paddingTop: 4}}>
                <Card style={{width: '100%'}}>
                    <CardContent>
                        <Grid container xs={12} md={12}>
                            <Grid item xs={12}>
                                <TCTitleLabel>{form.bookingId ? form.bookingId : '-'}</TCTitleLabel>
                            </Grid>
                            {/* Pindah ke header */}
                            <Grid item container xs={12} md={8} spacing={2} pt={1}>
                                <Grid item xs>
                                    <TCLabel subtitle>
                                        Order Date
                                    </TCLabel>
                                    <TCLabel>
                                        {form.createdDate ? moment(form.createdDate).format("YYYY/MM/DD HH:mm:ss") : '-'}
                                    </TCLabel>
                                </Grid>
                                <Grid item xs>
                                    <TCLabel subtitle>
                                        Order Status
                                    </TCLabel>
                                    <TCLabel>
                                        { orderStatusMap(form) }
                                    </TCLabel>
                                </Grid>
                                <Grid item xs>
                                    <TCLabel subtitle>
                                        User
                                    </TCLabel>
                                    <TCLabel>
                                        <a href={`/users/detail/${form.user ? form.user._id : null}`} style={{
                                            color: 'black'
                                        }}>{form.user ? form.user.name : form.createdBy ? `${form.createdBy.name} STC` : ''}</a>
                                    </TCLabel>
                                </Grid>
                                <Grid item xs>
                                    <TCLabel subtitle>
                                        Phone Number
                                    </TCLabel>
                                    <TCLabel>
                                        {form.user ? form.user.phoneNumber : ''}
                                    </TCLabel>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={8} spacing={2} mt={0.5}>
                                <Grid item container xs={12} md={12}>
                                    { 
                                        form.oldEventName && 
                                        <Grid item container xs={12} md={12} spacing={1}>
                                            <Grid item xs={6}>
                                                <TCInput
                                                    label={"Event Name"}
                                                    value={form.eventName}
                                                    onChange={(e) => {
                                                        onChange({
                                                            eventName: e.currentTarget.value
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TCInput
                                                    label={"Class Link"}
                                                    value={form.classLink}
                                                    onChange={(e) => {
                                                        onChange({
                                                            classLink: e.currentTarget.value
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    <TCInput label={"Description"} type={"textarea"} 
                                        value={form.description}
                                        rows={3}
                                        onChange={(e) => {
                                            onChange({
                                                description: e.currentTarget.value
                                            })
                                        }}
                                    />
                                    <Button sx={{marginTop: 1}} variant="contained"
                                        onClick={() => {
                                            updateOrderInfo()
                                        }}
                                    >Update</Button>
                                </Grid>
                            </Grid>
                            {/* {
                                data.orderType == "rent" &&
                                <Grid item container xs={12} md={8} spacing={2} pt={1}>
                                    <Grid item xs={12} md={12}>
                                        <TCInput label={"Description"} type={"textarea"} rows={3}/>
                                        <Button sx={{marginTop: 1}} variant="contained">Update</Button>
                                    </Grid>
                                </Grid>   
                            } */}
                            {/* Pindah ke header */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={12} style={{paddingTop: 4}}>
                <Card style={{width: '100%', height: '100%'}}>
                    <Tabs
                        // orientation="vertical"
                        orientation="horizontal"
                        variant="scrollable"
                        value={tabs}
                        onChange={(e, newValue) => {
                            setTabs(newValue)
                        }}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Details"/>
                        <Tab label="Payment"/>
                    </Tabs>
                    {
                        tabs == 0 &&
                        <TabContext index={0}>
                            <CardContent>
                                {/* <PlayerInfo playerId={data._id}/> */}
                                <OrderInfo data={form}/>
                            </CardContent>
                        </TabContext>
                    }
                    {
                        tabs == 1 &&
                        <TabContext index={1}>
                            <CardContent>
                                {/* <PlayerSession playerId={data._id}/> */}
                                <PaymentInfo data={form} paymentInformation={orderPaymentStatusMap}/>
                            </CardContent>
                        </TabContext>
                    }
                </Card>
            </Grid>
        </Grid>
    )
}

export default OrderDetail