import React, { useEffect, useState } from "react"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { Grid } from "@mui/material"
import { currencyFormat } from 'helper/NumberFormat';
import TableComponent from "components/TopCourtComponents/Table/TableComponent";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { courtTypeObj } from "views/pages/package/packageList";
import moment from "moment";

function PaymentInfo ({
    data,
    paymentInformation
}) {

    const [ paymentInfo, setPaymentInfo ] = useState([])

    useEffect(() => {
        console.log('here row', data)
        // loadOrderDetail()
        setPaymentInfo([
            {
                paymentType: data.orderType,
                provider: data.paymentType,
                amount: data.total,
                lastStatus: data.lastStatus,
                status: data.orderStatus[1].date ? data.orderStatus[1] : data.orderStatus[3].date ? data.orderStatus[3] : ''
            }
        ])
    }, [])

    const valueCustomator = (row) => {
        // lastStatus
        console.log('here check row', row)
        if (data.paymentType == "qris") {
            return ({
                type: mappingOrderLabel(row.paymentType),
                provider: ((row.lastStatus == "paid" || row.lastStatus == "payment_received" || row.lastStatus == "complete") && row.amount) ? mappingPaymentType(row.provider) : `-`,
                amount: ((row.lastStatus == "paid" || row.lastStatus == "payment_received" || row.lastStatus == "complete") && row.amount) ? `${currencyFormat(row.amount)}` : `-`,
                qrisPhoneNumber: data.qrisPhoneNumber || '-',
                qrisPayerName: data.qrisPayerName || '-',
                qrisIssuerName: data.qrisIssuerName || '-',
                status: row.status ? `${(row.lastStatus == "paid" || row.lastStatus == "payment_received" || row.lastStatus == "complete") ? `Paid` : `Failed`} on ${moment(row.status.date).format('YYYY/MM/DD HH:mm:ss')}` : `-`,
            })
        } else {
            return ({
                type: mappingOrderLabel(row.paymentType),
                provider: ((row.lastStatus == "paid" || row.lastStatus == "payment_received" || row.lastStatus == "complete") && row.amount) ? mappingPaymentType(row.provider) : `-`,
                amount: ((row.lastStatus == "paid" || row.lastStatus == "payment_received" || row.lastStatus == "complete") && row.amount) ? `${currencyFormat(row.amount)}` : `-`,
                status: row.status ? `${(row.lastStatus == "paid" || row.lastStatus == "payment_received" || row.lastStatus == "complete") ? `Paid` : `Failed`} on ${moment(row.status.date).format('YYYY/MM/DD HH:mm:ss')}` : `-`,
            })
        }
    }

    return (
        <Grid>
            <TableComponent columns={ data.paymentType == "qris" ? [
                    { label: "Type" },
                    { label: "Provider" },
                    { label: "Amount" },
                    { label: "QRIS Phone No" },
                    { label: "QRIS Payer Name" },
                    { label: "QRIS Issuer Name" },
                    { label: "Status" },
                ]:[
                    { label: "Type" },
                    { label: "Provider" },
                    { label: "Amount" },
                    { label: "Status" },
                ]}
                rows={paymentInfo ? paymentInfo : []}
                valueCustomator={valueCustomator}
            />
        </Grid>
    )
}

const mappingOrderLabel = (e) => {
    if (e == "value_pack") { return "Value Pack" }
    if (e == "booking") { return "Court Booking" }
    if (e == "service") { return "Service" }
    if (e == "class") { return "Class" }
    if (e == "rent") { return "Rent" }
    if (e == "buy") { return "Buy" }
    return ""
}

const mappingPaymentType = (e) => {
    if (e == "bank_transfer") { return "STC-HO" }
    if (e == "Bank Transfer") { return "STC-HO" }
    if (e == "Admin") { return "STC-HO" }
    if (e == "qris") { return "QRIS" }
    if (e == "va") { return "VA" }
    return ""
}

export default PaymentInfo